import { ServiceFactoryContext } from '@services/writing-guide-service-factory';
import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadSpinner from '../../LoadSpinner';
import '../Editor.css';
const CheckListEditor = () => {
    let navigate = useNavigate();
    const service = useContext(ServiceFactoryContext).CreateWritingGuideService();
    const { checkListId } = useParams();
    const [checkListItems, updateCheckListItems] = React.useState([]);
    const [isCreating, setCreateState] = React.useState(false);
    React.useEffect(() => {
        if (checkListId === undefined) {
            updateCheckListItems([]);
            return;
        }
        service
            .GetCheckList(checkListId)
            .then((result) => {
            updateCheckListItems((result === null || result === void 0 ? void 0 : result.items) != null ? result.items : []);
        })
            .catch((error) => {
            alert('Could not get checklist');
        }); //TODO: Remember to go through all catch statements for correct errorhandling
    }, [checkListId]);
    const submitCheckList = () => {
        if (checkListItems !== null && checkListId !== undefined) {
            setCreateState(true);
            service
                .UpdateCheckList(checkListId, checkListItems)
                .then((result) => {
                setCreateState(false);
                if (!(result.status === 200)) {
                    alert('Could not update checklist');
                }
                navigate(-1);
            })
                .catch((error) => {
                setCreateState(false);
                alert(error);
                alert('Could not update checklist');
                navigate(-1);
            });
        }
    };
    const updateCheckListItem = (index, itemContent) => {
        if (checkListItems !== null) {
            checkListItems[index] = itemContent;
            updateCheckListItems([...checkListItems]);
        }
    };
    const removeCheckListItem = (index) => {
        if (checkListItems !== null) {
            checkListItems.splice(index, 1);
            updateCheckListItems([...checkListItems]);
        }
    };
    const addCheckListItem = () => {
        if (checkListItems !== null) {
            checkListItems.push('');
            updateCheckListItems([...checkListItems]);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "editor-navigation" },
            React.createElement("button", { onClick: () => {
                    navigate(-1);
                } }, "Tilbage")),
        isCreating ? (React.createElement(React.Fragment, null,
            React.createElement("h2", null, "Opdatere huskeliste..."),
            React.createElement("div", { className: "load-spinner-wrapper" },
                React.createElement(LoadSpinner, { size: 'default' })))) : (React.createElement(React.Fragment, null, checkListItems !== null ? (React.createElement(React.Fragment, null, checkListItems.length > 0 ? (React.createElement("div", { className: "editor" },
            React.createElement("div", { className: "editor-manager" },
                React.createElement("h1", null, "Rediger huskelister:"),
                React.createElement("h2", null, "Huskelister:"),
                React.createElement("div", { className: "editor-writing-guide-check-list" },
                    checkListItems.map((item, index) => {
                        return (React.createElement("div", { key: index, className: "editor-writing-guide-check-list-item" },
                            React.createElement("input", { value: item, onChange: (e) => updateCheckListItem(index, e.target.value) }),
                            React.createElement("button", { onClick: () => {
                                    if (index > 0) {
                                        const item = checkListItems[index];
                                        checkListItems.splice(index, 1);
                                        checkListItems.splice(index - 1, 0, item);
                                        updateCheckListItems([...checkListItems]);
                                    }
                                } }, "op"),
                            React.createElement("button", { onClick: () => {
                                    if (index < checkListItems.length - 1) {
                                        const item = checkListItems[index];
                                        checkListItems.splice(index, 1);
                                        checkListItems.splice(index + 1, 0, item);
                                        updateCheckListItems([...checkListItems]);
                                    }
                                } }, "ned"),
                            React.createElement("button", { onClick: () => {
                                    removeCheckListItem(index);
                                } }, "fjern")));
                    }),
                    React.createElement("button", { onClick: () => {
                            addCheckListItem();
                        } }, "Tilf\u00F8j emne til huskelisten")),
                React.createElement("button", { disabled: isCreating, className: "editor-finish-button", onClick: () => {
                        submitCheckList();
                    } }, "Opdater checkliste")))) : (React.createElement("div", { className: "editor" },
            React.createElement("div", { className: "editor-manager" },
                React.createElement("h1", null, "Rediger huskelister:"),
                React.createElement("h2", null, "Huskelister:"),
                React.createElement("div", { className: "editor-writing-guide-check-list" },
                    React.createElement("button", { onClick: () => {
                            addCheckListItem();
                        } }, "Tilf\u00F8j emne til huskelisten")),
                React.createElement("button", { disabled: isCreating, className: "editor-finish-button", onClick: () => {
                        submitCheckList();
                    } }, "Opdater checkliste")))))) : (React.createElement(React.Fragment, null,
            React.createElement("h2", null, "Indl\u00E6ser huskeliste..."),
            React.createElement("div", { className: "load-spinner-wrapper" },
                React.createElement(LoadSpinner, { size: 'default' }))))))));
};
export default CheckListEditor;
