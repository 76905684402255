var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Service } from '../types';
/**
 * The generic service is a wrapper for the HTML fetch method,
 * and provides the service integrations with a uniformed functional API for messageing the services.
 */
var GenericService;
(function (GenericService) {
    /**
     * Sets the expected header details.
     *
     * @param sessionId The session id needed for validating the user credentials.
     * @param headers Optional, provide the fetch headers with custom items.
     *
     * @returns The headers format used by the HTML fetch API.
     */
    const setFetchHeaders = (headers) => {
        try {
            const fetchHeaders = new Headers();
            const timestamp = Math.floor(Date.now() / 1000);
            fetchHeaders.append('Accept', 'application/json');
            fetchHeaders.append('Content-Type', 'application/json');
            fetchHeaders.append('RequestDateTime', timestamp.toString());
            fetchHeaders.append('ProductName', 'intowords extension');
            if (headers !== undefined) {
                Object.keys(headers).forEach((key) => {
                    fetchHeaders.append(key, headers[key]);
                });
            }
            return fetchHeaders;
        }
        catch (error) {
            if (error instanceof Error) {
                handleError(error);
            }
            return new Headers();
        }
    };
    /**
     * Parse the queries as query params for the provided URL.
     *
     * @param url The url that the query params are appended to.
     * @param queries A key value object which defines the query params.
     *
     * @returns The URL with the query params appended as expected format for
     */
    const appendQueriesToUrl = (url, queries) => {
        let queryUrl = url;
        let queryDivider = '&';
        queryUrl = queryUrl + '?';
        Object.keys(queries).forEach((key, index, array) => {
            const query = queries[key];
            if (index === array.length - 1) {
                queryUrl = queryUrl + key + '=' + query;
            }
            else {
                queryUrl = queryUrl + key + '=' + query + queryDivider;
            }
        });
        return queryUrl;
    };
    /**
     * Send a request to the service.
     *
     * @param url The URL that is being messaged.
     * @param method The service method that is being requested.
     * @param headers Optional, custom header details can be provided.
     * @param body Optional, The body can be any form of object with key value pairs.
     * @param queries Optional, The query params that needs to be added to the URL.
     *
     * @returns The response from the service which is any form of object with key value pairs.
     */
    GenericService.sendRequest = (url, method, headers, body, queries) => __awaiter(this, void 0, void 0, function* () {
        if (queries) {
            url = appendQueriesToUrl(url, queries);
        }
        const fetchHeaders = setFetchHeaders(headers);
        let options;
        options = {
            method: method,
            headers: fetchHeaders,
        };
        if (body && (method === Service.Method.PUT || method === Service.Method.POST)) {
            if (typeof body === 'string') {
                options.body = body;
            }
            else {
                options.body = JSON.stringify(body);
            }
        }
        try {
            let response = yield fetch(url, options);
            if (!response.ok) {
                const errorResponse = yield response.json();
                handleError(new Error(errorResponse.error));
                throw Error(errorResponse.error);
            }
            return response;
        }
        catch (error) {
            if (error instanceof Error) {
                handleError(error);
                throw error;
            }
            else {
                const errorMessage = `${error}`;
                handleError(new Error(errorMessage));
                throw new Error(errorMessage);
            }
        }
    });
    function handleError(error) {
        //TODO: Create propper errorhandling
        console.log(error === null || error === void 0 ? void 0 : error.message);
    }
})(GenericService || (GenericService = {}));
export default GenericService;
