import { ServiceFactoryContext } from '@services/writing-guide-service-factory';
import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LoadSpinner from '../../LoadSpinner';
import '../Editor.css';
import WritingGuideItem from './WritingGuideItem';
const WritingGuideOverview = () => {
    let navigate = useNavigate();
    const [writingGuides, setwritingGuides] = React.useState([]);
    const [languageFilter, setLanguageFilter] = React.useState('default');
    const service = useContext(ServiceFactoryContext).CreateWritingGuideService();
    React.useEffect(() => {
        if (writingGuides.length === 0) {
            service
                .GetAllWritingGuides()
                .then((result) => {
                setwritingGuides(result);
            })
                .catch((error) => alert(error.message));
        }
    }, [writingGuides]);
    const submitDeleteWritingGuide = (writingGuideId) => {
        service
            .DeleteWritingGuide(writingGuideId)
            .then((response) => {
            if (response.ok) {
                const filteredWritingGuides = writingGuides.filter((item) => item.id !== writingGuideId);
                if (filteredWritingGuides) {
                    setwritingGuides(filteredWritingGuides);
                }
            }
            else {
                alert('Could not delete writingguide');
            }
        })
            .catch((error) => {
            console.error(error);
            alert('Could not delete writingguide');
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "editor-overview" },
            React.createElement("div", { className: "editor-navigation" },
                React.createElement("button", { onClick: () => {
                        navigate(-1);
                    } }, "Tilbage"),
                React.createElement(Link, { to: "/skriveguide/editor" }, "Opret ny skriveguide"),
                React.createElement("select", { 
                    //placeholder="Filter"
                    onChange: (e) => {
                        setLanguageFilter(e.target.value);
                    }, value: languageFilter },
                    React.createElement("option", { value: "default" }, "Filter efter sprog"),
                    React.createElement("option", { value: "da" }, "dansk"),
                    React.createElement("option", { value: "sv" }, "svensk"),
                    React.createElement("option", { value: "nb" }, "norsk bokm\u00E5l"),
                    React.createElement("option", { value: "nn" }, "norsk nynorsk"),
                    React.createElement("option", { value: "en" }, "engelsk"),
                    React.createElement("option", { value: "fo" }, "f\u00E6r\u00F8sk"),
                    React.createElement("option", { value: "nl" }, "nederlandsk"))),
            React.createElement("div", { className: "editor-overview-title" },
                React.createElement("p", null, "Skriveguidesoversigt")),
            React.createElement("div", { className: "editor-overview-items" }, writingGuides.length > 0 ? (React.createElement(React.Fragment, null, writingGuides.length > 0 ? (writingGuides.map((writingGuide, index) => {
                if (languageFilter !== 'default') {
                    if (writingGuide.language === languageFilter) {
                        return (React.createElement(WritingGuideItem, { key: index, writingGuideId: writingGuide.id, language: writingGuide.language, title: writingGuide.title, definition: writingGuide.definition, genre: writingGuide.genre, checkList: writingGuide.checkList, chapterIds: writingGuide.chapterIds, deleteCallback: (writingGuideId) => {
                                submitDeleteWritingGuide(writingGuideId);
                            } }));
                    }
                }
                else {
                    return (React.createElement(WritingGuideItem, { key: index, writingGuideId: writingGuide.id, language: writingGuide.language, title: writingGuide.title, definition: writingGuide.definition, genre: writingGuide.genre, checkList: writingGuide.checkList, chapterIds: writingGuide.chapterIds, deleteCallback: (writingGuideId) => {
                            submitDeleteWritingGuide(writingGuideId);
                        } }));
                }
                return null;
            })) : (React.createElement(React.Fragment, null,
                React.createElement("p", null, "Hov, der opstod en fejl."),
                React.createElement("button", { onClick: () => {
                        setwritingGuides([]);
                    }, className: "editor-finish-button" }, "Hent skriveguides igen"))))) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "load-spinner-wrapper" },
                    React.createElement(LoadSpinner, { size: 'default' })),
                React.createElement("p", null, "Vent venligst applikationen er igang med at finde alle skriveguides")))))));
};
export default WritingGuideOverview;
