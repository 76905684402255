// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-header {
  background-color: #961932;
  min-height: 13vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-header h1 {
  font-size: large;
  padding: 0 0 0 20px;
  color: white;
  padding: 0 0 25px 25px;
  text-decoration: none;
}

.app-header > a {
  text-decoration: none;
  color: white;
}

.app-header > a:hover {
  text-decoration: underline;
}

.app-body {
  background-color: #f0f0f0;
  min-height: 82vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
}

.app-footer {
  background-color: #282828;
  min-height: 5vh;
  min-width: 99vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,2BAA2B;EAC3B,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,2BAA2B;EAC3B,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd","sourcesContent":[".app-header {\n  background-color: #961932;\n  min-height: 13vh;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: flex-start;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.app-header h1 {\n  font-size: large;\n  padding: 0 0 0 20px;\n  color: white;\n  padding: 0 0 25px 25px;\n  text-decoration: none;\n}\n\n.app-header > a {\n  text-decoration: none;\n  color: white;\n}\n\n.app-header > a:hover {\n  text-decoration: underline;\n}\n\n.app-body {\n  background-color: #f0f0f0;\n  min-height: 82vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n  font-size: calc(10px + 2vmin);\n}\n\n.app-footer {\n  background-color: #282828;\n  min-height: 5vh;\n  min-width: 99vw;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
