import { ServiceFactoryContext } from '@services/writing-guide-service-factory';
import React, { useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadSpinner from '../../LoadSpinner';
import '../Editor.css';
import ChapterItem from './ChapterItem';
const ChapterOverview = () => {
    const navigate = useNavigate();
    const { writingGuideId } = useParams();
    const [chapters, setChapters] = React.useState([]);
    const service = useContext(ServiceFactoryContext).CreateWritingGuideService();
    React.useEffect(() => {
        if (writingGuideId === undefined)
            return;
        service
            .GetWritingGuide(writingGuideId)
            .then((writingGuide) => {
            setChapters(writingGuide.chapters);
        })
            .catch((error) => alert(error)); //TODO: Error handling is missing.  Consider whether to handle errors in base or in service layer.
    }, [writingGuideId]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "editor-overview" },
            React.createElement("div", { className: "editor-navigation" },
                React.createElement("button", { onClick: () => {
                        navigate(-1);
                    } }, "Tilbage"),
                React.createElement(Link, { to: `editor` }, "Rediger afsnit")),
            React.createElement("div", null,
                React.createElement("p", null, "Afsnitsoversigt")),
            React.createElement("div", { className: "editor-overview-items" }, chapters !== null ? (React.createElement(React.Fragment, null, chapters.length > 0
                ? chapters.map((chapter, index) => {
                    if (chapter.id === null)
                        return;
                    return React.createElement(ChapterItem, { key: index, chapterId: chapter.id, title: chapter.title, formulation: chapter.formulation, example: chapter.example });
                })
                : null)) : (React.createElement("div", { className: "load-spinner-wrapper" },
                React.createElement(LoadSpinner, { size: 'default' })))))));
};
export default ChapterOverview;
