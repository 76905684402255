// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-menu {
  max-height: 7vh;
  min-width: 99vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(8px + 2vmin);
}

.app-menu a {
  color: white;
  text-decoration: none;
  padding: 0 0 10px 25px;
  font-weight: bold;
}

.app-menu a:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/Menu.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,2BAA2B;EAC3B,4BAA4B;AAC9B;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".app-menu {\n  max-height: 7vh;\n  min-width: 99vw;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  font-size: calc(8px + 2vmin);\n}\n\n.app-menu a {\n  color: white;\n  text-decoration: none;\n  padding: 0 0 10px 25px;\n  font-weight: bold;\n}\n\n.app-menu a:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
