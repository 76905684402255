import React from 'react';
import { Link } from 'react-router-dom';
import '../Editor.css';
const WritingGuideItem = ({ writingGuideId, language, title, definition, genre, checkList, chapterIds, deleteCallback, }) => {
    const promptUser = () => {
        const promptTitle = `Skriv 'slet' og derefter klik 'OK' for at slette skriveguiden.\n${title}.`;
        const message = '';
        const response = window.prompt(promptTitle, message);
        switch (response) {
            case 'slet':
                deleteCallback(writingGuideId);
                break;
            case "'slet'":
                deleteCallback(writingGuideId);
                break;
            default:
                break;
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "editor-item" },
            React.createElement("div", { className: "editor-item-details" },
                React.createElement("h2", null, title),
                React.createElement("p", null,
                    React.createElement("span", null, "Sprog:"),
                    " ",
                    language),
                React.createElement("p", null,
                    React.createElement("span", null, "Definition:")),
                React.createElement("p", null, definition),
                React.createElement("p", null,
                    React.createElement("span", null, "Genre: "),
                    " ",
                    genre.genreName),
                React.createElement("p", null,
                    React.createElement("span", null, "SubGenre: "),
                    " ",
                    genre.subGenreName)),
            React.createElement("div", { className: "editor-item-actions" },
                React.createElement("button", { onClick: () => {
                        promptUser();
                    } }, "Slet"),
                React.createElement(Link, { to: `/skriveguide/editor/${writingGuideId}` }, "Rediger"),
                React.createElement(Link, { to: `/skriveguide/${writingGuideId}/huskeliste/${checkList.checkListId}` },
                    "Huskeliste: ",
                    checkList.items.length !== undefined ? checkList.items.length : 0,
                    " stk."),
                React.createElement(Link, { to: `/skriveguide/${writingGuideId}/afsnit` },
                    "Afsnit: ",
                    chapterIds.length !== undefined ? chapterIds.length : 0,
                    " stk.")))));
};
export default WritingGuideItem;
