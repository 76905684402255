import { createContext } from 'react';
import { WritingGuideService } from './writing-guide-service';
export class WritingGuideServiceFactory {
    constructor(configuration) {
        if (configuration !== undefined) {
            this.configuration = configuration;
        }
    }
    CreateWritingGuideService() {
        if (this.service !== undefined)
            return this.service;
        if (this.configuration === undefined)
            throw 'SessionId or WritingGuideUrl not set';
        this.service = new WritingGuideService(this.configuration);
        return this.service;
    }
}
export const ServiceFactoryContext = createContext(new WritingGuideServiceFactory());
