import { createSlice } from '@reduxjs/toolkit';
/**
 * The initial state.
 */
const initialState = {
    sessionId: undefined,
};
/**
 * A case reducer which allows us to update the user session at the state.
 * @param state The current state of the user store.
 * @param action The action that is provided to the reducer.
 */
const setSessionId = (state, action) => {
    return Object.assign({}, state, {
        sessionId: action.payload,
    });
};
/**
 * The session store slice which defines an instance of this store.
 */
const slice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setSessionId: setSessionId,
    },
});
// Allows us to get the actions from the user store.
export const actions = slice.actions;
// Allows us to get the reducer form the user store.
export const reducer = slice.reducer;
