/**
 * Defines the request types used by the Vitec Service for communication with the API.
 */
/**
 * Defines the available request methods for the API.
 */
export var Method;
(function (Method) {
    Method["POST"] = "post";
    Method["GET"] = "get";
    Method["DELETE"] = "delete";
    Method["PATCH"] = "patch";
    Method["PUT"] = "put";
})(Method || (Method = {}));
