import { ServiceFactoryContext } from '@services/writing-guide-service-factory';
import React, { useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadSpinner from '../../LoadSpinner';
import '../Editor.css';
import CheckListItem from './CheckListItem';
const CheckListOverview = () => {
    const navigate = useNavigate();
    const service = useContext(ServiceFactoryContext).CreateWritingGuideService();
    const [isLoading, setLoadingState] = React.useState(false);
    const { checkListId } = useParams();
    const [checkList, setCheckList] = React.useState(null);
    React.useEffect(() => {
        if (checkListId === undefined) {
            setCheckList(null);
            return;
        }
        setLoadingState(true);
        service
            .GetCheckList(checkListId)
            .then((result) => {
            setCheckList(result);
            setLoadingState(false);
        })
            .catch((error) => {
            alert('Could not get checklist');
            setLoadingState(false);
        }); //TODO: Remember to go through all catch statements for correct errorhandling
    }, [checkListId]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "editor-overview" },
            React.createElement("div", { className: "editor-navigation" },
                React.createElement("button", { onClick: () => {
                        navigate(-1);
                    } }, "Tilbage"),
                React.createElement(Link, { to: `editor` }, "Rediger huskeliste")),
            React.createElement("div", null,
                React.createElement("p", null, "Huskeliste oversigt")),
            React.createElement("div", { className: "editor-overview-items" }, checkList !== null ? (checkList.items.map((item, index) => {
                return React.createElement(CheckListItem, { key: index, item: item });
            })) : isLoading ? (React.createElement("div", { className: "load-spinner-wrapper" },
                React.createElement(LoadSpinner, { size: 'default' }))) : (React.createElement("div", null))))));
};
export default CheckListOverview;
