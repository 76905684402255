import React from "react";
import "./LoadSpinner.css";
const LoadSpinner = ({ size }) => {
    const getLoadSpinner = (size) => {
        switch (size) {
            case "button":
                return React.createElement("div", { className: "button-load-spinner" });
            default:
                return React.createElement("div", { className: "default-load-spinner" });
        }
    };
    return React.createElement(React.Fragment, null, getLoadSpinner(size));
};
export default LoadSpinner;
