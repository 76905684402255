import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { sessionStore } from './reducers';
/**
 * The application redux store.
 */
export const store = configureStore({
    reducer: {
        session: sessionStore.reducer,
    },
});
export const useAppDispatch = useDispatch;
export const useAppSelector = useSelector;
