import React from 'react';
import App from './App';
import './index.css';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(React.createElement(Provider, { store: store },
    React.createElement(App, null)));
