import LoadSpinner from '@components/LoadSpinner';
import Menu from '@components/Menu';
import ChapterEditor from '@components/editor/writing-guide/ChapterEditor';
import ChapterOverview from '@components/editor/writing-guide/ChapterOverview';
import CheckListEditor from '@components/editor/writing-guide/CheckListEditor';
import CheckListOverview from '@components/editor/writing-guide/CheckListOverview';
import WritingGuideEditor from '@components/editor/writing-guide/WritingGuideEditor';
import WritingGuideOverview from '@components/editor/writing-guide/WritingGuideOverview';
import { ServiceFactoryContext, WritingGuideServiceFactory } from '@services/writing-guide-service-factory';
import React, { useEffect } from 'react';
import { Link, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import { sessionStore, useAppDispatch, useAppSelector } from './store';
const BASE_URL = process.env.WG_BASE_URL;
const MV_SIGNON_URL = process.env.MV_SIGNON_URL;
const App = () => {
    const dispatch = useAppDispatch();
    const sessionId = useAppSelector((state) => state.session.sessionId);
    useEffect(() => {
        let capture = /[?]SessionID[=](.+)$/.exec(window.location.href);
        if (capture && typeof capture[1] !== 'undefined') {
            dispatch(sessionStore.actions.setSessionId(capture[1]));
            return;
        }
        if (sessionId === undefined) {
            window.location.href = `${MV_SIGNON_URL}/index.php?returnUrl=${window.location.protocol}//${window.location.host}&LoginOptionsInclude=MV-Login-skole,MV-Login-firma`;
        }
    }, [sessionId]);
    return (React.createElement(React.Fragment, null, sessionId === undefined || BASE_URL === undefined ? null : (React.createElement(React.Suspense, { fallback: React.createElement(LoadSpinner, { size: "button" }) },
        React.createElement(ServiceFactoryContext.Provider, { value: new WritingGuideServiceFactory({ SessionId: sessionId, BaseUrl: BASE_URL }) },
            React.createElement(Router, null,
                React.createElement("div", { className: "app" },
                    React.createElement("header", { className: "app-header" },
                        React.createElement(Link, { to: "/skriveguide" },
                            React.createElement("h1", null, "Material Creator")),
                        React.createElement(Menu, null)),
                    React.createElement("div", { className: "app-body" },
                        React.createElement(Routes, null,
                            React.createElement(Route, { path: "/", element: React.createElement(WritingGuideOverview, null) }),
                            React.createElement(Route, { path: "/skriveguide", element: React.createElement(WritingGuideOverview, null) }),
                            React.createElement(Route, { path: "/skriveguide/editor", element: React.createElement(WritingGuideEditor, null) }),
                            React.createElement(Route, { path: "/skriveguide/editor/:writingGuideId", element: React.createElement(WritingGuideEditor, null) }),
                            React.createElement(Route, { path: "/skriveguide/:writingGuideId/afsnit", element: React.createElement(ChapterOverview, null) }),
                            React.createElement(Route, { path: "/skriveguide/:writingGuideId/afsnit/editor", element: React.createElement(ChapterEditor, null) }),
                            React.createElement(Route, { path: "/skriveguide/:writingGuideId/huskeliste/:checkListId", element: React.createElement(CheckListOverview, null) }),
                            React.createElement(Route, { path: "/skriveguide/:writingGuideId/huskeliste/:checkListId/editor", element: React.createElement(CheckListEditor, null) }))),
                    React.createElement("footer", { className: "app-footer" }))))))));
};
export default App;
