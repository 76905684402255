import { Service } from '../types';
import GenericService from './fetch-client-generic';
//TODO: consider creating service classes for each independent object
export class WritingGuideService {
    constructor(configuration) {
        this.configuration = configuration;
    }
    GetAllWritingGuides() {
        return GenericService.sendRequest(`${this.configuration.BaseUrl}/v1/GetAllWritingGuides`, Service.Method.GET, { SessionID: this.configuration.SessionId })
            .then((result) => {
            return result.json();
        })
            .then((json) => {
            return json;
        });
    }
    GetWritingGuide(id) {
        return GenericService.sendRequest(`${this.configuration.BaseUrl}/v1/SearchWritingGuides`, Service.Method.GET, { SessionID: this.configuration.SessionId }, '', { Id: id, IsFullRead: 'true' })
            .then((result) => {
            return result.json();
        })
            .then((json) => {
            var tmp = json;
            if (tmp !== undefined && (tmp === null || tmp === void 0 ? void 0 : tmp.length) > 0) {
                return tmp[0];
            }
            return Promise.reject('Object does not exist');
        });
    }
    CreateWritingGuide(writingGuide) {
        return GenericService.sendRequest(`${this.configuration.BaseUrl}/v1/CreateWritingGuide`, Service.Method.POST, { SessionID: this.configuration.SessionId }, writingGuide).then((result) => {
            return result.json;
        });
    }
    UpdateWritingGuide(writingGuide) {
        return GenericService.sendRequest(`${this.configuration.BaseUrl}/v1/UpdateWritingGuide`, Service.Method.PUT, { SessionID: this.configuration.SessionId }, writingGuide).then((result) => {
            return result;
        });
    }
    GetCheckList(id) {
        return GenericService.sendRequest(`${this.configuration.BaseUrl}/v1/GetCheckList`, Service.Method.GET, { SessionID: this.configuration.SessionId }, '', {
            id: id,
        })
            .then((result) => {
            if (result.status == 200)
                return result.json();
            return null;
        })
            .then((json) => {
            if (json === null)
                return null;
            return json;
        });
    }
    UpdateCheckList(id, items) {
        var request = { CheckListId: id, items: items };
        return GenericService.sendRequest(`${this.configuration.BaseUrl}/v1/UpdateCheckList`, Service.Method.PUT, { SessionID: this.configuration.SessionId }, request).then((result) => {
            return result;
        });
    }
    DeleteWritingGuide(id) {
        return GenericService.sendRequest(`${this.configuration.BaseUrl}/v1/DeleteWritingGuide`, Service.Method.DELETE, { SessionID: this.configuration.SessionId }, '', { Id: id }).then((result) => {
            return result;
        });
    }
    UpdateWritingGuideWithChapters(writingGuideId, chapters) {
        return GenericService.sendRequest(`${this.configuration.BaseUrl}/v1/UpdateWritingGuideWithChapters`, Service.Method.PUT, { SessionID: this.configuration.SessionId }, chapters, { writingGuideId: writingGuideId })
            .then((result) => {
            return result.json();
        })
            .then((json) => {
            return json;
        });
    }
}
