import { ServiceFactoryContext } from '@services/writing-guide-service-factory';
import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IsValidChapter } from '../../../types/writingguide.types';
import LoadSpinner from '../../LoadSpinner';
import '../Editor.css';
const ChapterEditor = () => {
    let navigate = useNavigate();
    const { writingGuideId } = useParams();
    const [chapters, setChapters] = React.useState([]);
    const [isCreating, setCreateState] = React.useState(false);
    const service = useContext(ServiceFactoryContext).CreateWritingGuideService();
    React.useEffect(() => {
        if (writingGuideId === undefined)
            return;
        service
            .GetWritingGuide(writingGuideId)
            .then((writingGuide) => {
            setChapters(writingGuide.chapters);
        })
            .catch((error) => alert(error)); //TODO: Error handling is missing.  Consider whether to handle errors in base or in service layer.
    }, [writingGuideId]);
    const submitWritingGuide = () => {
        var shouldQuit = false;
        setCreateState(true);
        if (chapters !== null && chapters.length > 0) {
            for (let chapter of chapters) {
                if (!IsValidChapter(chapter)) {
                    shouldQuit = true;
                    setCreateState(false);
                    alert('Husk at udfylde alle felter'); //TODO: WE need a better validation on fields.
                    break;
                }
            }
        }
        if (shouldQuit || writingGuideId === undefined || chapters === null)
            return;
        service
            .UpdateWritingGuideWithChapters(writingGuideId, chapters)
            .then((result) => {
            setCreateState(false);
            navigate(-1);
        })
            .catch((error) => {
            alert(error);
            setCreateState(false);
        }); //TODO: Error handling is missing.  Consider whether to handle errors in base or in service layer.
    };
    const updateChapterItem = (index, chapter) => {
        if (chapters !== null) {
            chapters[index] = {
                id: chapter.id,
                sortKey: chapter.sortKey,
                title: chapter.title,
                formulation: chapter.formulation,
                example: chapter.example,
            };
            setChapters([...chapters]);
        }
    };
    const removeChapterItem = (index) => {
        if (chapters !== null) {
            chapters.splice(index, 1);
            setChapters([...chapters]);
        }
    };
    const addChapterItem = () => {
        if (chapters !== null) {
            chapters.push({
                id: null,
                sortKey: 0,
                title: '',
                formulation: '',
                example: '',
            });
            setChapters([...chapters]);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "editor-navigation" },
            React.createElement("button", { onClick: () => {
                    navigate(-1);
                } }, "Tilbage")),
        isCreating ? (React.createElement(React.Fragment, null,
            React.createElement("h2", null, "Opdatere afsnit..."),
            React.createElement("div", { className: "load-spinner-wrapper" },
                React.createElement(LoadSpinner, { size: 'default' })))) : (React.createElement(React.Fragment, null, chapters !== null ? (React.createElement(React.Fragment, null, chapters.length > 0 ? (React.createElement("div", { className: "editor" },
            React.createElement("div", { className: "editor-manager" },
                React.createElement("h1", null, "Rediger afsnit:"),
                React.createElement("div", { className: "editor-writing-guide-chapters" },
                    React.createElement("h2", null, "Afsnit:"),
                    chapters.map((item, index) => {
                        return (React.createElement("div", { key: index, className: "editor-writing-guide-chapter" },
                            React.createElement("h2", null, "Titel:"),
                            React.createElement("input", { type: "text", name: "writingGuideChapterTitle", placeholder: "Titel for dette afsnit.", value: item.title, onChange: (e) => {
                                    updateChapterItem(index, {
                                        id: item.id,
                                        sortKey: item.sortKey,
                                        title: e.target.value,
                                        formulation: item.formulation,
                                        example: item.example,
                                    });
                                } }),
                            React.createElement("h2", null, "Formulering:"),
                            React.createElement("textarea", { name: "writingGuideChapterFormulation", placeholder: "Formulering af hvad det p\u00E5g\u00E6ldene afsnit indeb\u00E6rer.", value: item.formulation, onChange: (e) => {
                                    updateChapterItem(index, {
                                        id: item.id,
                                        sortKey: item.sortKey,
                                        title: item.title,
                                        formulation: e.target.value,
                                        example: item.example,
                                    });
                                } }),
                            React.createElement("h2", null, "S\u00E6tningsdele eksempler"),
                            React.createElement("textarea", { name: "writingGuideChapterFormulation", placeholder: "feks. >Bogen handler om...<", value: item.example, onChange: (e) => {
                                    updateChapterItem(index, {
                                        id: item.id,
                                        sortKey: item.sortKey,
                                        title: item.title,
                                        formulation: item.formulation,
                                        example: e.target.value,
                                    });
                                } }),
                            React.createElement("div", null,
                                React.createElement("button", { onClick: () => {
                                        if (index > 0) {
                                            const item = chapters[index];
                                            chapters.splice(index, 1);
                                            chapters.splice(index - 1, 0, item);
                                            setChapters([...chapters]);
                                        }
                                    } }, "op"),
                                React.createElement("button", { onClick: () => {
                                        if (index < chapters.length - 1) {
                                            const item = chapters[index];
                                            chapters.splice(index, 1);
                                            chapters.splice(index + 1, 0, item);
                                            setChapters([...chapters]);
                                        }
                                    } }, "ned"),
                                React.createElement("button", { onClick: () => {
                                        removeChapterItem(index);
                                    } }, "fjern"))));
                    }),
                    React.createElement("button", { onClick: () => {
                            addChapterItem();
                        } }, "Tilf\u00F8j afsnit")),
                React.createElement("button", { disabled: isCreating, className: "editor-finish-button", onClick: () => {
                        submitWritingGuide();
                    } }, "Opdater afsnit")))) : (React.createElement("div", { className: "editor" },
            React.createElement("div", { className: "editor-manager" },
                React.createElement("h1", null, "Rediger afsnit:"),
                React.createElement("div", { className: "editor-writing-guide-chapters" },
                    React.createElement("h2", null, "Afsnit:"),
                    React.createElement("button", { onClick: () => {
                            addChapterItem();
                        } }, "Tilf\u00F8j afsnit")),
                React.createElement("button", { disabled: isCreating, className: "editor-finish-button", onClick: () => {
                        submitWritingGuide();
                    } }, "Opdater afsnit")))))) : (React.createElement(React.Fragment, null,
            React.createElement("h2", null, "Indl\u00E6ser afsnit..."),
            React.createElement("div", { className: "load-spinner-wrapper" },
                React.createElement(LoadSpinner, { size: 'default' }))))))));
};
export default ChapterEditor;
