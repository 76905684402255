import { ServiceFactoryContext } from '@services/writing-guide-service-factory';
import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadSpinner from '../../LoadSpinner';
import '../Editor.css';
const WritingGuideEditor = () => {
    let navigate = useNavigate();
    const { writingGuideId } = useParams();
    const [writingGuide, setWritingGuideState] = React.useState(null);
    const [title, updateTitle] = React.useState('');
    const [language, updateLanguage] = React.useState('default');
    const [genreName, updateGenreName] = React.useState('');
    const [subGenreName, updateSubGenreName] = React.useState('');
    const [definition, updateDefinition] = React.useState('');
    const [isProcessing, setProcessingState] = React.useState(false);
    const [isUpdating, setUpdatingState] = React.useState(false);
    const [isLoading, setLoadingState] = React.useState(false);
    const service = useContext(ServiceFactoryContext).CreateWritingGuideService();
    React.useEffect(() => {
        if (writingGuideId) {
            setLoadingState(true);
            service
                .GetWritingGuide(writingGuideId)
                .then((writingGuide) => {
                //Consider containing them in (then().then().then() =>{} and throw error on failsed)
                updateTitle(writingGuide.title);
                updateLanguage(writingGuide.language);
                updateDefinition(writingGuide.definition);
                setUpdatingState(true);
                setWritingGuideState(writingGuide);
                if (writingGuide.genre.genreName) {
                    updateGenreName(writingGuide.genre.genreName);
                }
                if (writingGuide.genre.subGenreName) {
                    updateSubGenreName(writingGuide.genre.subGenreName);
                }
                setLoadingState(false);
            })
                .catch((error) => alert(error)); //TODO: Error handling is missing.  Consider whether to handle errors in base or in service layer.
        }
    }, [writingGuideId]);
    const submitCreateWritingGuide = () => {
        if (language !== 'default') {
            setProcessingState(true);
            service
                .CreateWritingGuide({
                language: language,
                title: title,
                definition: definition,
                genreName: genreName,
                subGenreName: subGenreName,
                checkListItems: [],
            })
                .then((response) => {
                setProcessingState(false);
                navigate(-1);
            })
                .catch((error) => {
                setProcessingState(false);
                alert('Noget gik galt... Husk at udfylde alle påkrævede felter');
            }); //TODO: ERROR HANDLING... Det SKAL HÅNDTERES ORDENTLIGT, HVIs DER SKER EN FEJL
        }
    };
    const submitUpdateWritingGuide = () => {
        if (language !== 'default' && writingGuide !== null) {
            setProcessingState(true);
            service
                .UpdateWritingGuide({
                writingGuideId: writingGuide.id,
                language: language,
                title: title,
                definition: definition,
                genreName: genreName,
                subGenreName: subGenreName,
            })
                .then((response) => {
                setProcessingState(false);
                navigate(-1);
            })
                .catch((error) => {
                setProcessingState(false);
                alert('Noget gik galt... Husk at udfylde alle påkrævede felter');
            }); //TODO: ERROR HANDLING... Det SKAL HÅNDTERES ORDENTLIGT, HVIs DER SKER EN FEJL
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "editor-navigation" },
            React.createElement("button", { onClick: () => {
                    navigate(-1);
                } }, "Tilbage")),
        isProcessing ? (React.createElement(React.Fragment, null, isUpdating ? (React.createElement(React.Fragment, null,
            React.createElement("h1", null, "Updatere skriveguide..."),
            React.createElement("div", { className: "load-spinner-wrapper" },
                React.createElement(LoadSpinner, { size: 'default' })))) : (React.createElement(React.Fragment, null,
            React.createElement("h1", null, "Opretter skriveguide..."),
            React.createElement("div", { className: "load-spinner-wrapper" },
                React.createElement(LoadSpinner, { size: 'default' })))))) : (React.createElement("div", { className: "editor" },
            React.createElement("div", { className: "editor-manager" }, isLoading ? (React.createElement(React.Fragment, null,
                React.createElement("h1", null, "Henter skriveguide..."),
                React.createElement("div", { className: "load-spinner-wrapper" },
                    React.createElement(LoadSpinner, { size: 'default' })))) : (React.createElement(React.Fragment, null,
                isUpdating ? React.createElement("h1", null, "Opdater skriveguide:") : React.createElement("h1", null, "Opret skriveguide:"),
                React.createElement("h2", null, "Skriveguide information:"),
                React.createElement("input", { type: "text", name: "writingGuideTitle", placeholder: "Titlen for denne skriveguide.", value: title, onChange: (e) => {
                        updateTitle(e.target.value);
                    } }),
                React.createElement("select", { id: "languageSelection", name: "writingGuideLanguage", value: language, onChange: (e) => {
                        updateLanguage(e.target.value.toLowerCase());
                    } },
                    React.createElement("option", { value: "default" }, "V\u00E6lg Sprog"),
                    React.createElement("option", { value: "da" }, "dansk"),
                    React.createElement("option", { value: "sv" }, "svensk"),
                    React.createElement("option", { value: "nb" }, "norsk bokm\u00E5l"),
                    React.createElement("option", { value: "nn" }, "norsk nynorsk"),
                    React.createElement("option", { value: "en" }, "engelsk"),
                    React.createElement("option", { value: "fo" }, "f\u00E6r\u00F8sk"),
                    React.createElement("option", { value: "nl" }, "nederlandsk")),
                React.createElement("h2", null, "Genre:"),
                React.createElement("input", { type: "text", name: "writingGuideGenre", placeholder: "Genren for denne skriveguide.", value: genreName, onChange: (e) => {
                        updateGenreName(e.target.value);
                    } }),
                React.createElement("input", { type: "text", name: "writingGuideSubGenre", placeholder: "Subgenren for denne skriveguide.", value: subGenreName, onChange: (e) => {
                        updateSubGenreName(e.target.value);
                    } }),
                React.createElement("h2", null, "Definition:"),
                React.createElement("textarea", { name: "writingGuideDefinition", placeholder: "Definitionen for denne skriveguide.", value: definition, onChange: (e) => {
                        updateDefinition(e.target.value);
                    } }),
                isUpdating ? (React.createElement("button", { disabled: isProcessing, className: "editor-finish-button", onClick: () => {
                        submitUpdateWritingGuide();
                    } }, "Opdater Skriveguide")) : (React.createElement("button", { disabled: isProcessing, className: "editor-finish-button", onClick: () => {
                        submitCreateWritingGuide();
                    } }, "Opret Skriveguide")))))))));
};
export default WritingGuideEditor;
