import React from "react";
import "../Editor.css";
const ChapterItem = ({ chapterId, title, formulation, example, }) => {
    return (React.createElement("div", { className: "editor-item" },
        React.createElement("div", { className: "editor-item-details" },
            React.createElement("h2", null, title),
            React.createElement("p", null,
                React.createElement("span", null, "Formulering:")),
            React.createElement("p", null, formulation),
            React.createElement("p", null,
                React.createElement("span", null, "Eksempler:")),
            React.createElement("p", null, example))));
};
export default ChapterItem;
